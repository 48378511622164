/// The different roles a user can have in an organization.
export var OrganizationRole;
(function (OrganizationRole) {
    /// Owners can manage the organization and its apps, and can add and remove
    /// users. There can only be one owner.
    OrganizationRole["owner"] = "owner";
    /// Admins can do everything owners can do, except transfer ownership and
    /// delete the organization.
    OrganizationRole["admin"] = "admin";
    /// Members can view and manage apps in the organization.
    OrganizationRole["member"] = "member";
})(OrganizationRole || (OrganizationRole = {}));
/// The different types of organizations we support.
export var OrganizationType;
(function (OrganizationType) {
    /// Personal organizations have a single owner member (the user they are
    /// associated with). They are created automatically when a user signs up.
    OrganizationType["personal"] = "personal";
    /// Team organizations can have multiple members and only subscribing users
    /// are able to create them.
    OrganizationType["team"] = "team";
})(OrganizationType || (OrganizationType = {}));
