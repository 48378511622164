/// A release build of an application that is distributed to devices.
/// A platform that a release can be distributed to.
export var ReleasePlatform;
(function (ReleasePlatform) {
    ReleasePlatform[ReleasePlatform["android"] = 0] = "android";
    ReleasePlatform[ReleasePlatform["ios"] = 1] = "ios";
})(ReleasePlatform || (ReleasePlatform = {}));
/// A platform-specific status for a release.
export var ReleaseStatus;
(function (ReleaseStatus) {
    ReleaseStatus[ReleaseStatus["draft"] = 0] = "draft";
    ReleaseStatus[ReleaseStatus["active"] = 1] = "active";
})(ReleaseStatus || (ReleaseStatus = {}));
